<script setup>
import { Head, Link, useForm } from "@inertiajs/vue3";
import AuthenticationCard from "@/Components/AuthenticationCard.vue";
import AuthenticationCardLogo from "@/Components/AuthenticationCardLogo.vue";
import Checkbox from "@/Components/Checkbox.vue";
import InputError from "@/Components/InputError.vue";
import InputLabel from "@/Components/InputLabel.vue";
import ButtonPrimary from "@/Components/ButtonPrimary.vue";
import TextInput from "@/Components/TextInput.vue";
import EmptyLayout from "@/Layouts/EmptyLayout.vue";
import GoogleOAuthSignInButton from "@/Components/GoogleOAuthSignInButton.vue";
import { ref } from "vue";
import CollapsibleContainer from "@/Components/CollapsibleContainer.vue";
import MicrosoftSignInButton from "@/Components/MicrosoftSignInButton.vue";

defineProps({
    canResetPassword: Boolean,
    status: String,
});

defineOptions({
    layout: EmptyLayout,
});

const form = useForm({
    email: "",
    password: "",
    remember: false,
});

const submit = () => {
    form.transform((data) => ({
        ...data,
        remember: form.remember ? "on" : "",
    })).post(route("login"), {
        onFinish: () => form.reset("password"),
    });
};

const showPasswordInput = ref(false);
</script>

<template>
    <Head title="Log in" />

    <AuthenticationCard>
        <template #logo>
            <AuthenticationCardLogo />
        </template>

        <div
            v-if="status"
            class="mb-4 text-sm font-medium text-green-600"
        >
            {{ status }}
        </div>

        <div class="flex flex-col items-center justify-center gap-y-5 py-5">
            <GoogleOAuthSignInButton :href="route('auth.redirect', 'google')" />

            <MicrosoftSignInButton :href="route('auth.redirect', 'azure')" />
        </div>

        <div class="mb-5 flex items-center justify-between gap-x-5">
            <div class="h-[1px] grow bg-gray-300"></div>

            <div class="text-xs text-gray-400">or</div>

            <div class="h-[1px] grow bg-gray-300"></div>
        </div>

        <CollapsibleContainer
            class="flex justify-center"
            :open="!showPasswordInput"
        >
            <button
                class="text-sm text-gray-500 hover:underline"
                @click="showPasswordInput = true"
            >
                Log in with password
            </button>
        </CollapsibleContainer>

        <form @submit.prevent="submit">
            <CollapsibleContainer
                class=""
                :open="showPasswordInput"
            >
                <div>
                    <InputLabel
                        for="email"
                        value="Email"
                    />

                    <TextInput
                        id="email"
                        v-model="form.email"
                        type="email"
                        class="mt-1 block w-full"
                        required
                        autocomplete="username"
                    />

                    <InputError
                        class="mt-2"
                        :message="form.errors.email"
                    />
                </div>

                <div class="mt-4">
                    <InputLabel
                        for="password"
                        value="Password"
                    />

                    <TextInput
                        id="password"
                        v-model="form.password"
                        type="password"
                        class="mt-1 block w-full"
                        required
                        autocomplete="current-password"
                    />

                    <InputError
                        class="mt-2"
                        :message="form.errors.password"
                    />
                </div>

                <div class="mt-4 block">
                    <label class="flex items-center">
                        <Checkbox
                            v-model:checked="form.remember"
                            name="remember"
                        />

                        <span class="ml-2 text-sm text-gray-600">Remember me</span>
                    </label>
                </div>

                <div class="mt-4 flex items-center justify-end">
                    <Link
                        v-if="canResetPassword"
                        :href="route('password.request')"
                        class="rounded-md text-sm text-gray-600 underline hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Forgot your password?
                    </Link>

                    <ButtonPrimary
                        class="ml-4"
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                    >
                        Log in
                    </ButtonPrimary>
                </div>
            </CollapsibleContainer>
        </form>
    </AuthenticationCard>
</template>
